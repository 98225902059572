<template>
  <modal :modal.sync="modal" @changeModal="e => $emit('change-modal', e)">
    <div class="modal__content">
      <div class="modal__header">
        <h2 class="modal__title">
          Solicitar coleta
        </h2>
      </div>

      <div class="d-flex flex-wrap">
        <h3 class="col-12 modal__subtitle">Selecione a forma:</h3>
        <div class="modal__form--group col-12">
          <div
            class="banks__item"
            v-for="form in forms"
            :key="form.form"
            @click="formData.form = form.form"
          >
            <input
              type="radio"
              @click="
                getCollectPoints(
                  form.form == 1 ? 'collectorsByItem' : 'pointsByItem',
                  form.form
                )
              "
              :value="form.form"
              name="selected-form"
              :id="`form-${form.form}`"
              class="selected-material selected-form"
            />

            <label :for="`form-${form.form}`">
              <div class="banks__item--content">
                <span>{{ form.label }}</span>
              </div>
            </label>
          </div>
        </div>
        <template v-if="formData.form">
          <h3 class="col-12 modal__subtitle" v-if="formData.form === 1">
            Selecione o endereço
          </h3>

          <div class="modal__form--group col-12" v-if="formData.form === 1">
            <div
              class="banks__item"
              v-for="address in addresses"
              :key="address.address"
              @click="formData.address_id = address.id"
            >
              <input
                type="radio"
                :value="address.id"
                name="selected-address"
                :id="`address-${address.id}`"
                class="selected-material"
              />

              <label :for="`address-${address.id}`">
                <div class="banks__item--content">
                  <div>
                    {{
                      `${address.street}, ${address.number} - ${address.district}`
                    }}
                    <br />
                    {{
                      `${address.cep} - ${address.city.name} - ${address.province.initials}`
                    }}
                  </div>
                  <div
                    v-if="addresses.length > 1"
                    @click.prevent="destroyAddress(address)"
                  >
                    <a class="material__item--anchor red" href="#">
                      <i class="far fa-trash-alt red"></i> Excluir
                    </a>
                  </div>
                </div>
              </label>
            </div>

            <!--  -->
          </div>
        </template>
        <template v-if="formData.address_id || formData.form === 2">
          <h3 class="col-12 modal__subtitle">
            {{ formData.form === 1 ? 'Coletor' : 'Ponto de Coleta' }}
          </h3>
          <!-- {{ collectors }} -->
          <div class="modal__form--group col-12" v-if="collectors.length">
            <div
              class="banks__item"
              v-for="collector in collectors"
              :key="collector.id"
              @click="formData.collector_id = collector.user.id"
            >
              <input
                type="radio"
                :value="collector.user.id"
                name="selected-collector"
                :id="`collector-${collector.user.id}`"
                class="selected-material"
                @click="setAddress(collector.address_id, collector.amount)"
              />

              <label :for="`collector-${collector.user.id}`">
                <div class="banks__item--content">
                  <span>
                    <span style="font-weight: bold; font-size: 18px">
                      {{ collector.user.name }}
                    </span>
                    <span style="font-size: 12px">
                      {{ collector.company.company_name }}
                    </span>
                    <small
                      >Coleta mínima de {{ collector.amount }}
                      {{ collector.unity }}</small
                    >
                    <br />
                    <small style="color: #000">
                      <i class="fas fa-dollar-sign"></i>
                      Receba  R$ {{ collector.value }} por
                      {{ collector.unity }}
                    </small>
                  </span>
                </div>
              </label>
            </div>
          </div>
          <div class="modal__form--group col-12" v-if="collectors.length">
            <div>
              <label>Observações</label>
              <br>
              <br>
              <textarea :multiline="true" ref="textareaObj" :v-model="observation" style="padding: 10px; border: 1px solid gray; width: 100%;" />
            </div>
          </div>
          <p v-else class="text-center">
            {{
              formData.form === 1
                ? 'Ainda não temos nenhum coletor para a sua região, estamos trabalhando nisso.'
                : 'Ainda não temos nenhum ponto de coleta para a sua região.'
            }}
          </p>
        </template>

        <template v-if="formData.collector_id">
          <button
            v-if="formData.form === 1"
            type="button"
            @click="saveCollection"
            class="button_outline"
          >
            Solicitar Coleta
          </button>
          <button
            v-if="formData.form === 2"
            type="button"
            @click="saveCollectionPoint"
            class="button_outline"
          >
            Solicitar Coleta
          </button>
        </template>
      </div>
    </div>
  </modal>
</template>

<script>
import Vue from 'vue'
import swal from 'sweetalert'
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'

const defaultForm = {
  id: null,
  form: null,
  collector_id: null,
  item_id: null,
  recyclable_items: null,
  not_recyclable_items: null,
  address_id: null,
  price: null
}
const defaultFormAddress = {
  zip_code: null,
  district: '',
  complement: '',
  street: '',
  number: '',
  city_id: '',
  province_id: '',
  address_id: ''
}
const defaultLoadedByZip = {
  street: false,
  number: false,
  district: false,
  complement: false
}
export default Vue.extend({
  data: () => ({
    forms: [
      { form: 1, label: 'Coletar no meu endereço' },
      { form: 2, label: 'Levar no ponto de coleta' }
    ],
    formData: { ...defaultForm },
    formAddress: { ...defaultFormAddress },
    loadedByZip: { ...defaultLoadedByZip },
    rules: {},
    addresses: [],
    collectors: [],
    collectPoints: [],
    loading: false,
    local: 0,
    deletingAddress: false,
    recyclable: {
      yes: true,
      not: false
    },
    price: null,
    observation: null
  }),
  computed: {
    ...mapGetters('province', ['provinces']),
    ...mapGetters('city', ['cities']),
    valid () {
      return true
    },
    validAddress () {
      const form = this.formAddress
      if (!form.zip_code) return false
      if (!form.district) return false
      if (!form.street) return false
      if (!form.number) return false
      if (!form.province_id) return false
      if (!form.city_id) return false

      return true
    }
  },
  methods: {
    ...mapActions('closet', ['fetchItems']),
    ...mapActions('province', { fetchProvinces: 'fetch' }),
    ...mapActions('city', { fetchCities: 'fetch', getViaCEP: 'getViaCEP' }),
    async saveCollection () {
      if (!this.valid) {
        swal({
          title: 'Atenção!',
          text: 'Preencha todos os campos.',
          icon: 'error',
          button: 'Ok'
        })
        return
      }
      const url = 'items/request-collection'

      try {
        this.formData.price = this.price
        this.observation = this.$refs.textareaObj.value
        this.formData.observation = this.observation

        console.log('obs', this.observation)
        console.log('form', this.formData)
        const { data, status } = await axios.post(url, this.formData)
        this.alert(status === 200 ? 'success' : 'error', data.message)
        this.fetchItems(0)
        this.closeForm()
      } catch (error) {
        const { status, data } = error.response
        this.alert(status === 200 ? 'success' : 'error', data.message)
      } finally {
        this.loadingCollection = false
      }
    },
    async saveCollectionPoint () {
      if (!this.valid) {
        swal({
          title: 'Atenção!',
          text: 'Preencha todos os campos.',
          icon: 'error',
          button: 'Ok'
        })
        return
      }
      const url = 'collection/saveCollectPoint'
      try {
        const body = {
          id: this.formData.id,
          collector_id: this.formData.collector_id
        }
        body.price = this.price
        body.observation = this.observation
        const { data, status } = await axios.post(url, body)
        this.alert(status === 200 ? 'success' : 'error', data.message)
        this.fetchItems(0)
        this.closeForm()
      } catch (error) {
        const { status, data } = error.response
        this.alert(status === 200 ? 'success' : 'error', data.message)
      } finally {
        this.loadingCollection = false
      }
    },
    async saveCollectionPointsssss () {
      if (!this.valid) {
        swal({
          title: 'Atenção!',
          text: 'Preencha todos os campos.',
          icon: 'error',
          button: 'Ok'
        })
        return
      }
      const url = 'collection/saveCollectPoint'
      try {
        const { data, status } = await axios.post(url, this.formData)
        this.alert(status === 200 ? 'success' : 'error', data.message)
        this.fetchItems(0)
        this.closeForm()
      } catch (error) {
        const { status, data } = error.response
        this.alert(status === 200 ? 'success' : 'error', data.message)
      } finally {
        this.loadingCollection = false
      }
    },
    async saveAddress () {
      if (!this.validAddress) {
        return this.alert(
          'error',
          'Preencha corretamente todos campos de endereço.',
          null
        )
      }

      try {
        await axios.post('addresses', { ...this.formAddress, type: 'PONTO' })
        this.getAddresses()
        this.alert('success', 'Endereço adicionado com sucesso', null)
        this.formAddress = { ...defaultFormAddress }
      } catch (error) {
        this.alert('error', 'Ocorreu um erro ao adicionar endereço.', null)
      }
    },
    destroyAddress ({ id }) {
      if (this.deletingAddress) return
      this.deletingAddress = true
      const deleteAddress = async () => {
        try {
          const url = `addresses/${id}`
          await axios.delete(url)
          this.alert('success', 'Endereço deletado com sucesso', null)
          this.getAddresses()
          return true
        } catch (error) {
          return error
        }
      }

      const showError = e => {
        this.alert('error', 'Erro ao deletar endereço', null)
      }

      swal({
        title: 'Tem certeza?',
        text: 'Após deletado não sera possível recuperar o registro',
        icon: 'warning',
        buttons: ['Cancelar', true]
      })
        .then(async res => {
          if (!res) return
          const deleted = await deleteAddress()
          if (deleted === true) return
          throw deleted
        })
        .catch(showError)
        .finally(() => {
          this.deletingAddress = false
        })
    },
    async getAddresses () {
      const { data } = await axios.get('addresses')
      this.addresses = data
    },
    async getCollectPoints (selected, tipo) {
      const url = selected + `/${this.formData.item_id}`
      const { data } = await axios.get(url)
      this.collectors = data.data
    },
    async setAddress (selected, price) {
      this.address_id = selected
      this.price = price
    },
    async getCollectors (itemId) {
      const url = `collectorsByItem/${itemId}`
      const { data } = await axios.get(url)
      this.collectors = data
    },
    closeForm () {
      this.formData = { ...defaultForm }
      this.$emit('change-modal', false)
      this.clearSelected()
    },
    clearSelected () {
      const selects = Array.from(document.querySelectorAll('.selected-form'))
      selects.map(i => (i.checked = false))
    },
    alert (
      type = 'success',
      text = 'Verifique todos os campos.',
      successAction = () => this.$router.push('/coletas-solicitadas')
    ) {
      swal({
        title: type === 'success' ? 'Sucesso!' : 'Ooops!',
        icon: type === 'success' ? 'success' : 'error',
        text,
        button: type === 'success' ? null : 'Ok',
        timer: type === 'success' ? 1250 : 3000
      }).then(() => {
        if (type === 'success' && successAction !== null) successAction()
      })
    }
  },

  props: {
    modal: Boolean,
    selectedMaterial: Object
  },
  components: {
    modal: () => import('@/components/modal')
  },
  created () {
    this.getAddresses()
  },
  mounted () {
    this.fetchProvinces()
  },
  watch: {
    async 'formAddress.zip_code' (val) {
      if (!val || val.length < 8) return
      const data = await this.getViaCEP({ zipCode: val })
      this.formAddress = Object.assign(this.formAddress, data)
      Object.keys(data).map(item => {
        this.loadedByZip[item] = !!data[item]
      })
    },
    modal () {
      this.formData = { ...defaultForm }
      this.clearSelected()
    },
    selectedMaterial: {
      deep: true,
      handler (val) {
        if (!val) return null
        this.formData.item_id = val.item_id
        this.formData.reco = val.item_id
        this.formData.id = val.id
        this.formData.not_recyclable_items = val.not_recyclable
        this.formData.recyclable_items = val.recyclable
        this.getCollectors(val.item_id)
      }
    }
  }
})
</script>

<style scoped>
.have-destination {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}
.red {
  color: #ce2c2c;
}
button {
  margin: auto;
  margin-top: 1rem;
}
.banks__item--content {
  margin-left: 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.banks__item--content div:last-child {
  padding-left: 16px;
}

.banks__item input:checked + label::after {
  left: 10px !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
}
.recyclable {
  margin: 1.5rem 0;
  position: relative;
}

.recyclable-section p {
  position: relative;
  cursor: pointer;
}

.modal-chevron {
  color: #05b446;
  position: absolute;
  right: 10px;
  font-size: 22px;
  top: 50%;
  transition: 0.2s;
  transform: translateY(-50%) rotate(360deg);
}
.modal-chevron.open {
  transform: translateY(-50%) rotate(180deg);
}

.recyclable-content {
  margin: 1rem auto 2rem auto;
  width: calc(100% - 1rem);
}

.recyclable-content li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
}

.recyclable-content li i {
  margin-right: 1rem;
  font-size: 14px;
  display: block;
}

.recyclable-content li i.fa-check {
  color: #47be71;
}
</style>
